import React from 'react';
import { Container, Typography, Card, CardMedia, CardContent, CardActions, Button, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fad811',
    },
  },
});

const ProductDetailPage = ({ product }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" style={{ marginTop: '2rem' }}>
        <Card>
          <CardMedia
            component="img"
            alt={product.name}
            height="500"
            image={product.image}
            title={product.name}
          />
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              {product.name}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {product.description}
            </Typography>
            <Typography variant="h5" component="p" gutterBottom>
              Precio: ${product.price}
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Stock: {product.stock}
            </Typography>
          </CardContent>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary">
                  Añadir al Carrito
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary">
                  Comprar Ahora
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </ThemeProvider>
  );
};

export default ProductDetailPage;
