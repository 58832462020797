import { useEffect, useState } from 'react';
import './css/main.css'
import Card from './Card';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import CircularProgress from '@mui/material/CircularProgress';

const firebaseConfig = {
  apiKey: "AIzaSyCXILcmKo19iEeyx3qH95Ryrg7UxUSsC48",
  authDomain: "tcgsalta.firebaseapp.com",
  projectId: "tcgsalta",
  storageBucket: "tcgsalta.appspot.com",
  messagingSenderId: "757880131171",
  appId: "1:757880131171:web:bc52a2d3a386e05f5a7256"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchedProducts = [];
    
    async function fetchMyAPI() {
      setIsLoading(true)
      const productsCollection = collection(db, "Products");
      const q = query(productsCollection, orderBy('priority'));
      const querySnapshot = await getDocs(q);
  
      querySnapshot.forEach((doc) => {
        fetchedProducts.push({
          id: doc.id,
          ...doc.data()
        });
      });
  
      setProducts(fetchedProducts);
      setIsLoading(false)
    }
  
    if (!products.length) {
      fetchMyAPI();
    }
  }, []);

  return (
    <div id="wrapper">
      <div id="main">
        <div class="inner">
          <header>
            <h1>TCG SALTA</h1>
            <h3>Tu destino de coleccionables en Salta</h3>
          </header>
          {!isLoading ? (
            <section class="tiles">
              {products.map((product) => (
                <Card onClick={product.id} stock={product.stock} prices={product.prices} productName={product.name} img={product.image}/>
              ))}
            </section>
          ) : (
            <section class="tiles" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress color='warning'/>
            </section>
          )}
        </div>
      </div>
      <footer id="footer">
        <div class="inner">
          <section>
            <h2>Contactanos</h2>
            <ul class="icons">
              <li><a href="https://wa.me/5493874668477?text=Hola, queria hacer una consulta." class="icon brands style2 fa-whatsapp" target="_blank"><span class="label">Whatsapp</span></a></li>
            </ul>
          </section>
        </div>
      </footer>
			</div>
  );
}

export default App;
