import React from 'react';
import './Card.scss'; // Import the CSS file for styling

const Card = ({ img, productName, stock, prices }) => {
  console.log(prices, '!!')

  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });

  return (
    <div className="card">
      <div className="left">
        <img
          className="wordmark"
          src={require('./images/PokemonLogo.png')}
          alt="star wars"
        />
      </div>
      <div className="right">
        <img
          className="helmet"
          src={img.includes('firebase') ? img : require(`./images/${img}`)}
          alt="helmet"
        />
        <div className="productInfo">
          <h1>
            {productName}
          </h1>
          { prices.length > 1 ? (
            prices.map((price, i) => <h3>Llevando {i+1}: <b>{formatter.format(price)}</b> c/u</h3>
            )
          ) : (
            <h2>{formatter.format(prices[0])}</h2>
          )}
          <div className="details">
            En Stock: <b>{stock}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;